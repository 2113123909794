import * as Sentry from '@sentry/react';
import {
  osName,
  osVersion,
  mobileModel,
  mobileVendor,
} from 'react-device-detect';
import * as Amplitude from '@amplitude/node';

import { makeId } from '../lib/helpers';
import { localStore } from '../lib/storage';
import { isMobile, isTablet } from '../lib/getDeviceSize';
import config from './config';
const ASYNC_STORAGE_KEY = 'amplitudeUserId';

// Details of browser/device
const COMMON_PROPS = {
  platform: 'Web',
  os_name: osName,
  os_version: osVersion,
  device_brand: isMobile() || isTablet() ? mobileVendor : null,
  device_model: isMobile() || isTablet() ? mobileModel : null,
  device_type: isMobile() ? 'Mobile' : isTablet() ? 'Tablet' : 'Browser',
};

export class AmplitudeAnalytics {
  amplitudeClient: any;
  amplitudeUserId: string | null;
  constructor(apiKey: any) {
    this.amplitudeUserId = null;
    this.initialize(apiKey);
    try {
      // Try to fetch the user's Amplitude ID from localStorage
      this.amplitudeUserId = localStore.getItem(ASYNC_STORAGE_KEY);
      // If the user doesn't have a Amplitude ID, create and store one
      if (!this.amplitudeUserId) {
        const newId = makeId(32);
        this.amplitudeUserId = newId;
        localStore.setItem(ASYNC_STORAGE_KEY, newId);
      }
    } catch (error) {
      // Something went wrong with local store
      Sentry.captureException(error);
    }
  }

  initialize = (apiKey: string) => {
    // Initializing Amplitude instance
    this.amplitudeClient = Amplitude.init(apiKey);
  };

  identify = (userEmail: string) => {
    try {
      this.amplitudeUserId = userEmail;
      localStore.setItem(ASYNC_STORAGE_KEY, userEmail);
    } catch (error) {
      // Something went wrong with local store
      Sentry.captureException(error);
    }
  };

  register = (user: any, userProps: any) => {
    try {
      const { email, firstName, lastName, phoneNumber } = user;
      this.amplitudeClient.logEvent({
        event_type: 'Register',
        user_id: email,
        ...COMMON_PROPS,
        user_properties: {
          Email: email,
          'First Name': firstName,
          'Last Name': lastName,
          Phone: phoneNumber,
          ...userProps,
        },
      });
      this.identify(email);
    } catch (error) {
      // Something went wrong while Amplitude log event
      Sentry.captureException(error);
    }
  };

  // Trigger logEvent
  track = (eventName: string, userProps?: any, eventProps?: any) => {
    try {
      this.amplitudeClient.logEvent({
        event_type: eventName,
        user_id: this.amplitudeUserId,
        ...COMMON_PROPS,
        user_properties: {
          ...userProps,
        },
        event_properties: {
          ...eventProps,
        },
      });
      if (eventName === 'Logout') {
        const newId = makeId(32);
        this.amplitudeUserId = newId;
        localStore.setItem(ASYNC_STORAGE_KEY, newId);
      }
    } catch (error) {
      // Something went wrong while Amplitude log event
      Sentry.captureException(error);
    }
  };
}

export default new AmplitudeAnalytics(config.amplitudeAnalyticsKey);
