import MixpanelAnalytics from '../../services/mixpanel';

// # Example: Destination interface for the Mixpanel SDK. Replace the Mixpanel implementation with your own tracking SDK methods
export const customDestination = {
  identify: (userEmail: string) => {
    // MixpanelAnalytics.identify(userEmail);
  },
  logEvent: (eventName: string, eventProperties: object) => {
    // MixpanelAnalytics.track(eventName, eventProperties);
  },
  setUserProperties: (operation: any, props: any) => {
    // MixpanelAnalytics._people(operation, props);
  },
};
