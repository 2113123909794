import 'whatwg-fetch';
import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Router from 'next/router';

import ToriiLoader from '../components/utility/ToriiLoader';
import Layout from '../components/layout/';
import withReduxStore from '../lib/with-redux-store';
import theme from '../lib/theme';
import track from '../lib/track';

import Avo from '../lib/avoAnalytics/avo';
import { customDestination } from '../lib/avoAnalytics/customDestination';

Avo.initAvo({ env: Avo.AvoEnv.Prod }, customDestination);
/*
 * Global Stylesheet import.
 * Individual imports are not permitted in nextJS.
 */
import '../styles/sass/main.scss';
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

export interface Props {
  Component: any;
  initialReduxState: any;
  pageProps: any;
  reduxStore: any;
  router: any;
}

// Initialize Sentry
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'test') {
  Sentry.init({
    dsn: 'https://2682908ddaae43a1a52fc4183d2c18ca@sentry.io/185113',
    environment: process.env.NODE_ENV,
    ignoreErrors: ['Resource blocked by content blocker'],
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    tracesSampleRate: 1.0,
  });
}

class ToriiApp extends App<Props> {
  persistor: any;
  tracked: boolean;

  constructor(props: any) {
    super(props);
    this.persistor = persistStore(props.reduxStore);
    this.tracked = false;

    Router.events.on('routeChangeComplete', url => {
      this.trackPageView();
    });
  }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles: any = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
    track.initialize();
    if (!this.tracked) {
      if (typeof window !== 'undefined') {
        window.onload = () => {
          this.trackPageView();
        };
      }
    }
    this.tracked = true;
  }

  trackPageView = () => {
    if (this.props.router && this.props.router.asPath) {
      track.pageView(this.props.router.asPath);
    }
  };

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    if (super.componentDidCatch) {
      super.componentDidCatch(error, errorInfo);
    }
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props;
    if (typeof window === 'undefined') {
      return (
        <ThemeProvider theme={theme}>
          <Provider store={reduxStore}>
            <Head>
              <title key="title">
                Torii Homes: Exceptional customer service meets cutting edge
                tech for seamless, personalized, and stress-free home buying.
              </title>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
              />
            </Head>
            <Component {...pageProps} />
          </Provider>
        </ThemeProvider>
      );
    }
    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Provider store={reduxStore}>
            <PersistGate
              loading={
                <ToriiLoader
                  imageProps={{ style: { height: 400, width: 350 } }}
                />
              }
              persistor={this.persistor}
            >
              <CssBaseline />
              <Head>
                <title key="title">
                  Torii Homes: Exceptional customer service meets cutting edge
                  tech for seamless, personalized, and stress-free home buying.
                </title>
                <meta
                  name="viewport"
                  content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                />
              </Head>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </PersistGate>
          </Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

export default withReduxStore(ToriiApp);
