import moment from 'moment';
import { localStore, storageKeys } from './storage';
import {
  brokerFeePercentage,
  COPYRIGHT_TEXT,
  MINIMUM_SUPPORTED_PRICE,
  STATES_LIST,
} from './constants';
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} from 'unique-names-generator';
import { isEmpty, uniq, get } from 'lodash';
import queryString from 'query-string';

export const missingImage =
  'https://torii-images.s3.amazonaws.com/t/missing_1024_768.png';

/**
 * Return the postal abbeviaton for a state
 * @param {string} state - a full-length string of a U.S. state
 * @returns {string} - the postal abbreviation for the state
 */
export function abbreviateState(state: string): string {
  if (!state) {
    return state;
  }
  const lowerState = state.toLowerCase();
  const stateMapping: any = {
    alabama: 'AL',
    alaska: 'AK',
    arizona: 'AZ',
    arkansas: 'AR',
    california: 'CA',
    colorado: 'CO',
    connecticut: 'CT',
    delaware: 'DE',
    florida: 'FL',
    georgia: 'GA',
    hawaii: 'HI',
    idaho: 'ID',
    illinois: 'IL',
    indiana: 'IN',
    iowa: 'IA',
    kansas: 'KS',
    kentucky: 'KY',
    louisiana: 'LA',
    maine: 'ME',
    maryland: 'MD',
    massachusetts: 'MA',
    michigan: 'MI',
    minnesota: 'MN',
    mississippi: 'MS',
    missouri: 'MO',
    montana: 'MT',
    nebraska: 'NE',
    nevada: 'NV',
    'new hampshire': 'NH',
    'new jersey': 'NJ',
    'new mexico': 'NM',
    'new york': 'NY',
    'north carolina': 'NC',
    'north dakota': 'ND',
    ohio: 'OH',
    oklahoma: 'OK',
    oregon: 'OR',
    pennsylvania: 'PA',
    'rhode island': 'RI',
    'south carolina': 'SC',
    'south dakota': 'SD',
    tennessee: 'TN',
    texas: 'TX',
    utah: 'UT',
    vermont: 'VT',
    virginia: 'VA',
    washington: 'WA',
    'washington d.c.': 'DC',
    'west virginia': 'WV',
    wisconsin: 'WI',
    wyoming: 'WY',
  };
  if (!stateMapping[lowerState]) {
    return state;
  }
  return stateMapping[lowerState];
}

/**
 * Expand abbreviated State
 * @param abbreviatedState - ex. CA
 * @returns - Returns California
 */
export function expandAbbreviatedState(abbreviatedState: string): string {
  const stateName = STATES_LIST[abbreviatedState];
  if (stateName) {
    return stateName;
  }
  return abbreviatedState;
}

/**
 * Return a formatted date after adding a specified number of days to today's date
 * @export
 * @param {number} [days=0] - Number of days to add to today's date
 * @param {string} [display='MM/DD/YYYY'] - The format in which to display the new date
 * @param {Date} [today=new Date()] - Today's date
 * @returns {string} - The new date with days added
 */
export function addAndFormatDate(
  days = 0,
  display = 'MM/DD/YYYY',
  today = new Date(),
) {
  return moment(today)
    .add(days, 'days')
    .format(display);
}

/**
 * Format an address in the standard form
 * @param {*} listing - a Listing object
 * @param {boolean} [short=false] - true if we want a short form address,<br>
 * ex. 123 Main Street #2 as opposed to 123 Main Street #2, Boston, MA 02125
 * @param {boolean} [formatUnit=true] - true if we want to reformat the listing's unit number
 * @returns {string} - a formatted address string
 */
export function addressString(listing: any, short = false, formatUnit = true) {
  if (!listing) {
    return '';
  }
  let unitNo = '';
  let streetNumToUse = '';
  if (listing.unitNo) {
    if (formatUnit) {
      unitNo = ` ${formatUnitNumber(listing.unitNo)}`;
    } else {
      unitNo = ` ${listing.unitNo}`;
    }
  }
  // Listing street numbers are stored as different names
  if (listing.streetNum) {
    streetNumToUse = listing.streetNum;
  } else {
    streetNumToUse = listing.streetNo;
  }
  const streetAddress = `${streetNumToUse} ${listing.streetName}${unitNo}`;
  if (short) {
    return streetAddress;
  }
  return `${streetAddress}, ${listing.town}, ${abbreviateState(
    listing.state,
  )} ${listing.zipCode && listing.zipCode.substring(0, 5)}`;
}

/**
 * Create an array of objects from a map
 * @param {Map} map - a map that to be converted to an array
 * @returns {Array<Object>} - an array of objects
 */
export function arrayFromMap(map: Map<any, any>) {
  return Array.from(map, ([key, val]) => ({ item: key, value: val }));
}

/**
 * Creates an Array from an Object
 * @export
 * @param {Object} obj - an object to be converted into an array
 * @returns {Array} - the converted array
 */
export function arrayFromObject(obj: Object) {
  return arrayFromMap(mapFromObject(obj));
}

/**
 * Calculate what value we want to send for ad conversions
 * @export
 * @param {number} maxBudget
 * @param {string} state
 * @param {string} market
 * @returns
 */
export function calculateConversionValue(maxBudget: number, state: string) {
  const minTargetedPriceCA = 1000000;
  const minTargetedPriceMA = 600000;
  const minTargetedPriceNH = 400000;
  let value = 0;
  if (
    (state === STATES_LIST.CA && maxBudget >= minTargetedPriceCA) ||
    (state === STATES_LIST.MA && maxBudget >= minTargetedPriceMA) ||
    (state === STATES_LIST.NH && maxBudget >= minTargetedPriceNH)
  ) {
    value = maxBudget * brokerFeePercentage;
  }
  return value;
}

/**
 * Convert a list of words to single camelcase string
 * @export
 * @param {string} str - A string to split up
 * @returns
 */
export function camelize(str: string) {
  let strToReturn = str;
  // Normalize underscores
  if (strToReturn.indexOf('_') >= 0) {
    strToReturn = strToReturn.toLowerCase().replace(/_/g, ' ');
    // Check if a string is a single uppercase word
  } else if (strToReturn === strToReturn.toUpperCase()) {
    strToReturn = strToReturn.toLowerCase();
  }
  return strToReturn
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
      index === 0 ? letter.toLowerCase() : letter.toUpperCase(),
    )
    .replace(/\s+/g, '');
}

/**
 * camelCase all of the keys in an object
 * @export
 * @param {Object} obj - The object to camelCase
 * @returns
 */
export function camelizeObject(obj: any) {
  const objToReturn = Object.assign({}, obj);
  const keys = Object.keys(objToReturn);
  for (let i = 0; i < keys.length; i += 1) {
    const initialKey = keys[i];
    const initialValue = obj[initialKey];
    const camelizedKey = camelize(initialKey);
    delete objToReturn[initialKey];
    objToReturn[camelizedKey] = initialValue;
  }
  return objToReturn;
}

/**
 * Capitalize every word in a camelCase string, and add spaces between
 * @param {string} val - a string to be split up and capitalized
 * @returns {string} - the split up and capitalized string
 */
export function capitalize(val: string) {
  const result = val.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

/**
 * Check if any keys in an object are not null
 * @export
 * @param {Object} obj - The object to check
 * @returns {boolean} - True if any keys are not null
 */
export function checkAnyValidProperties(obj: any) {
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    if (obj[keys[i]] !== null) {
      return true;
    }
  }
  return false;
}

export const ACTIVE_STATUSES = [
  'New',
  'Active',
  'Price Changed',
  'Back on Market',
  'Extended',
  'Reactivated',
  'Coming Soon',
];

/**
 * Check to see if a listing is currently live
 * @param {Object} listing - a listing object
 * @returns {boolean} - true if the listing is live
 */
export function checkStatusLive(listing: any) {
  return ACTIVE_STATUSES.indexOf(listing.status) >= 0;
}

/**
 * Check to see if a listing is sold already
 * @param {Object} listing - a listing object
 * @return {boolean } - true if the listing is sold
 */
export function checkStatusSold(listing: any) {
  return listing.status === 'Sold';
}

/**
 * Allow multiple onEnter hooks in routes.js, and executes them in parallel
 * @param {Array} hooks - a list of hooks that we're passing to an API endpoint
 * @returns {func} - a function composed of multiple hooks to be executed in parallel
 */
export function composeEnterHooksParallel(...hooks: any[]) {
  const callbacksRequired = hooks.reduce((totalCallbacks, hook) => {
    if (hook.length >= 3) {
      totalCallbacks += 1;
    }
    return totalCallbacks;
  }, 0);
  return function onEnter(
    nextState: any,
    replace: any,
    executeTransition: any,
  ) {
    const callbacksInvoked = 0;
    hooks.forEach((hook: any) => {
      hook.call(hook, nextState, replace, () => {
        if (callbacksRequired === callbacksInvoked + 1) {
          executeTransition();
        }
      });
    });
    if (!callbacksRequired) {
      executeTransition();
    }
  };
}

/**
 * Assumes that the array to be deduped is already sorted by descending date
 * @param {Array<Object>} arr - an array of objects to be deduped
 * @param {string} key - the key in objects that we're looking for matches on
 * @returns {Array<Object} - the deduped array
 */
export function dedupeArrayOfObjects(arr: any[], key: string) {
  if (arr.length === 0) {
    return arr;
  }
  for (let i = 1; i < arr.length; ) {
    if (arr[i - 1][key] === arr[i][key]) {
      arr.splice(i, 1);
    } else {
      i += 1;
    }
  }
  return arr;
}

/**
 * Calculate the distance between two lat/longs
 * @param {number} lat1 - the first latitude value
 * @param {number} lon1 - the first longitude value
 * @param {number} lat2 - the second latitude value
 * @param {number} lon2 - the second longitude value
 * @param {string} unit - the unit, either kilometers, nautical miles, or miles (default)
 * @param {number} [rounding=2] - decimal precision
 * @returns {number} - The distance in values
 */
export function distance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string = 'M',
  rounding: number = 2,
) {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  // Return kilometers
  if (unit === 'K') {
    return (dist * 1.609344).toFixed(rounding);
  }
  // Return nautical miles
  if (unit === 'N') {
    return (dist * 0.8684).toFixed(rounding);
  }
  const rounded = dist.toFixed(rounding);
  return rounded;
}

/**
 * Format a number as rounded USD without cents
 * @param {number} number - A number that we want to format
 * @param {number} [rounding=1] - The number of decimals with which to round the number
 * @returns {string} - A currency-formatted string
 */
export function formatAsDollars(number: any, rounding: number = 1) {
  if (!number) {
    return number;
  }
  const rounded = Math.round(number / rounding) * rounding;
  const asDollars = new Intl.NumberFormat('en-US').format(rounded);
  return `$${asDollars}`;
}

/**
 * Format a number with a thousands separator
 * @param {number} number - the number to be formatted, ex. 1000
 * @param {number} [rounding=1] - decimal precision
 * @returns {string} - the formatted number with commas, ex. 1,000
 */
export function formatAsNumber(number: number, rounding: number = 1) {
  if (!number) {
    return number;
  }
  const rounded = Math.round(number / rounding) * rounding;
  const asNumber = new Intl.NumberFormat('en-US').format(rounded);
  return `${asNumber}`;
}

/**
 * Format a string with the number of bathrooms of a listing
 * @param {any} listing - A listing object
 * @returns {string} - A string in the form of 3/1 for 3 full baths and 1 half bath
 */
export function formatBathrooms(listing: any): string {
  return `${listing.noFullBaths}${
    parseInt(listing.noHalfBaths, 10) === 0 ? '' : `/${listing.noHalfBaths}`
  }`;
}

/**
 * If a unit number looks like it's just a unit without a label (ex. "Apt."), add a # in front of it.
 * @param {*} unitNo - a listing's unit number
 * @returns {string} - a properly formatted unit number
 */
export function formatUnitNumber(unitNo: number | string) {
  if (!unitNo) {
    return unitNo;
  }
  const alphanumeric = /^[0-9a-zA-Z]+$/;
  // Check if the unit number is only a number, as in "2", or a combination of letters and numbers, like "2A"
  if (isInt(unitNo) || String(unitNo).match(alphanumeric)) {
    return `#${unitNo}`;
  }
  return unitNo;
}

/**
 * Check if a provided value is a valid email address
 * @param {string} value - a string that we are verifying if it looks like a valid email
 * @returns {boolean} - true if the string looks like a valid email
 */
export function isEmail(value: string) {
  if (!value) {
    return false;
  }
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,11}$/i.test(value);
}

/**
 * Check if a value is an integer, or at least looks like one
 * @param {*} value - a value passed to check its type
 * @returns {boolean} - true if the value is an integer
 */
export function isInt(value: any) {
  return !isNaN(value) && !isNaN(parseInt(value, 10));
}

/**
 * Check if we're running in a production environment
 * @export
 * @returns - true if we not in localhost
 */
export function isProduction() {
  if (
    (typeof window !== 'undefined' && location.hostname === 'localhost') ||
    location.hostname === '127.0.0.1'
  ) {
    return false;
  }
  return true;
}

/**
 * Generate a random ID string
 * @param {number} [length=5] - the length of the new ID
 * @returns {string} - the random ID
 */
export function makeId(length = 5) {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

/**
 * Create a map from an Object
 * @param {Object} obj - an object to be converted into a Map
 * @returns {Map} - a map
 */
export function mapFromObject(obj: any) {
  const map = new Map();
  Object.keys(obj).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      map.set(key, obj[key]);
    }
  });
  return map;
}

/**
 * Format a number to look like a phone number
 * @param {string} value - a string that we want to be formatted like a phone number
 * @param {string} previousValue - previously typed string, to confirm<br>
 * that the user is typing forward, not deleting
 * @returns {string} - the phone number-looking string
 */
export function normalizePhone(value: string, previousValue?: string) {
  if (!value) {
    return value;
  }
  if (typeof value !== 'string') {
    value = `${value}`;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return `${onlyNums}`;
    }
    if (onlyNums.length === 6) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10,
  )}`;
}

/**
 * Take a number in string format, and return it looking like currency for display purposes
 * @param {*} value - a string or number that we want to look like currency
 * @returns {string} - the currency-looking string
 */
const intl = new Intl.NumberFormat();
export function normalizePrice(value: any, precision: any = 0) {
  let toReturn = value;
  if (!value) {
    return null;
  }
  const min = 0;
  const max = 99999999;
  if (typeof toReturn === 'string') {
    toReturn = toReturn.replace(/[^0-9.]+/g, '');
    toReturn = parseFloat(toReturn);
  }

  if (toReturn < min) {
    toReturn = min;
  }
  if (toReturn > max) {
    toReturn = max;
  }
  let formatted = '';
  if (String(value).match(/\d+/)) {
    formatted = toReturn
      .toFixed(precision)
      .replace(/./g, (c: any, i: any, a: any) =>
        i && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c,
      );
  }
  return formatted;
}

/**
 * Take a number in string format, and return it looking like currency with $ as prefix
 * @param {*} value - a string or number that we want to look like currency ex. 5000
 * @returns {string} - the currency-looking string with '$' ex. $5,000
 */
export function normalizePriceWithPrefix(value: any) {
  const onlyNums = value && value.replace(/[^\d]/g, '');
  if (!onlyNums) {
    return '';
  }
  return `$${normalizePrice(onlyNums)}`;
}

/**
 * Remove all non-digits from a string
 * @param {string} value - a string that has extra non-number characters, ex. 1,000
 * @returns {string} - a number-looking string, ex. 1000
 */
export function parseNumber(value: string) {
  if (!value || typeof value !== 'string') {
    return value;
  }
  return value.replace(/[^0-9]/g, '');
}

/**
 * Remove all non-digits and return 10 digit phone number
 * @param {string} value - a string that looks like a number
 * @returns {number} - a 10-digit phone number, ex. 1234567890
 */
export function parsePhoneNumber(value: any) {
  if (!value || typeof value !== 'string') {
    return value;
  }
  return value.replace(/[^0-9]/g, '').substring(0, 10);
}

/**
 * Format a number as a percentage
 * @param {number} val - a number that we want to look like a percentage, ex. 0.11
 * @param {number} [decimals=1] - the decimal-precision of the percentage that we want
 * @returns {string} - the percentage-looking sting, ex: 11%
 */
export function percentage(val: number, decimals: number = 1) {
  return `${(val * 100).toFixed(decimals)}%`;
}

/**
 * Remove an object from an array of objects when it has a particular value for a key
 * @param {Array<Object>} arr - an array of objects
 * @param {string} key - the key inside the objects that we're looking for
 * @param {string} val - the value of the passed in key that we're looking for
 * @returns {Array<Object>} - the new array without the matched object(s)
 */
export function removeFromArrayOfObjects(arr: any[], key: string, val: any) {
  const arrayToUse = Object.assign([], arr);
  for (let i = 0; i < arr.length; i += 1) {
    if (arrayToUse[i][key] === val) {
      arrayToUse.splice(i, 1);
      break;
    }
  }
  return arrayToUse;
}

/**
 * Remove properties from an object
 * @export
 * @param {Object} obj - The object from which we'd like to remove properties
 * @param {Array} props - List of properties to remove
 * @returns {Object} - The new object with properties removed
 */
export function removeObjectProperties(obj: any, props: string[] = []) {
  const toReturn = obj;
  for (let i = 0; i < props.length; i += 1) {
    if (Object.prototype.hasOwnProperty.call(toReturn, props[i])) {
      delete toReturn[props[i]];
    }
  }
  return toReturn;
}

/**
 * Format a date the way we expect it in offer documents (like Dotloop)
 * @export
 * @param {Date} date - The date to format
 * @param {string} [display='MM/DD/YYYY'] - The format in which to display the date
 * @returns
 */
export function formatDate(date: Date, display: string = 'MM/DD/YYYY') {
  return moment(date).format(display);
}

/**
 * Round a float to a set number of decimal points
 * @export
 * @param {number} number - A numeber to round
 * @param {number} [decimalValue=3] - Decimal precision
 * @returns {number} - A rounded number
 */
export function roundFloat(number: number, decimalValue: number = 3) {
  const roundValue = 10 ** decimalValue;
  return Math.round(number * roundValue) / roundValue;
}

/**
 * Sort an array of objects by a specified key
 * @export
 * @param {Array<Object>} arr - The list of objects to sort
 * @param {string} key - The key to sort the objects by
 * @param {boolean} [reverse=false] - Whether or not to sort the objects in reverse order
 * @returns {Array} - A sorted list
 */
export function sortArrayOfObjects(
  arr: any[],
  key: string,
  reverse: boolean = false,
) {
  const sorted = arr.sort((a, b) => {
    const keyA = a[key];
    const keyB = b[key];
    // Compare the two values
    if (!reverse) {
      if (keyA < keyB) {
        return -1;
      }
      if (keyA > keyB) {
        return 1;
      }
    } else {
      if (keyA < keyB) {
        return 1;
      }
      if (keyA > keyB) {
        return -1;
      }
    }
    return 0;
  });
  return sorted;
}

/**
 * Format a string showing the listing agent and/or brokerage
 * @export
 * @param {listing} listing
 * @returns {String}
 */
export function formatListingAgent(listing: any) {
  const { agentName, agentMlsId, brokerName, brokerMlsNumber } = listing;
  if (
    !listing ||
    (!agentName && !agentMlsId && !brokerName && !brokerMlsNumber)
  ) {
    return '';
  }
  return `Listed by:${agentName ? ` ${agentName}` : ''}${
    agentMlsId ? ` #${agentMlsId}` : ''
  }${(agentMlsId || agentName) && (brokerName || brokerMlsNumber) ? ',' : ''}${
    brokerName ? ` ${brokerName}` : ''
  }${brokerMlsNumber ? ` #${brokerMlsNumber}` : ''}`;
}

/**
 * Format a Property price
 * @export
 * @param {number} price - Price of a property, ex. 850000
 * @returns {String} - Represents currency value in the with suffix ex. 850K
 */
export function formatShortPrice(price: number) {
  const si = [
    { value: 1, symbol: '', rounding: 0 },
    { value: 1e3, symbol: 'K', rounding: 0 },
    { value: 1e6, symbol: 'M', rounding: 2 },
    { value: 1e9, symbol: 'B', rounding: 2 },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i: number;
  for (i = si.length - 1; i > 0; i--) {
    if (price >= si[i].value) {
      break;
    }
  }
  const formatted = (price / si[i].value)
    .toFixed(si[i].rounding)
    .replace(rx, '$1');
  // If the home price is rounded to exactly 1000, display it as 1 and go up one for the symbol
  if (formatted === '1000') {
    return `1${si[i + 1].symbol}`;
  }
  return `${formatted}${si[i].symbol}`;
}

/**
 * Format an address to be used in a URL
 * @param {*} listing - a Listing object
 * @param {string} address - an address string
 * Removes commas and hashes, replaces blank spaces with hyphens, and encodes ampersands
 * @returns {string} - a formatted address string eg: 300-Commercial-Street-#514-Boston-MA-02109
 */
export function formatUrlAddress(listing: any, address?: string) {
  if (address) {
    return encodeURIComponent(
      address
        .replace(/,/g, '')
        .replace(/#/g, '')
        .replace(/ /g, '-')
        .replace(/&/g, '%26'),
    );
  }
  const formattedAddress = addressString(listing);
  const encoded = encodeURIComponent(
    formattedAddress
      .replace(/,/g, '')
      .replace(/#/g, '')
      .replace(/ /g, '-')
      .replace(/&/g, '%26'),
  );
  return encoded;
}

/**
 * Format a list of propTypes from a search
 * @export
 * @param {string[]} propTypes
 * @returns
 */
export function formatPropTypes(propTypes: string[] | string) {
  if (!propTypes) {
    return null;
  }
  if (typeof propTypes === 'string') {
    if (propTypes.toLowerCase().includes('condo')) {
      return 'Condo/Townhouse';
    } else {
      return propTypes;
    }
  }
  // Format condo string
  propTypes.forEach((propType: string, index: number) => {
    if (propType.toLowerCase().includes('condo')) {
      propTypes[index] = 'Condo/Townhouse';
    }
  });

  return (propTypes || []).join(', ');
}

/**
 * Format a user's search details to display it as a string
 * @export
 * @param {*} query
 * @returns A string that joins the parts of the query, ex. "3 br | 2 ba"
 */
export function formatSearchDetails(query: any) {
  if (!query) {
    return '';
  }
  const outputArray: any = [];
  const { minPrice, maxPrice, minBr, minBath, minSf, propType } = query;
  if (minPrice && maxPrice) {
    const priceRange = `$${formatShortPrice(minPrice)} - $${formatShortPrice(
      maxPrice,
    )}`;
    outputArray.push(priceRange);
  } else if (minPrice) {
    outputArray.push(`> $${formatShortPrice(minPrice)}`);
  } else if (maxPrice) {
    outputArray.push(`< $${formatShortPrice(maxPrice)}`);
  }
  if (minBr) {
    outputArray.push(`${minBr} br`);
  }
  if (minBath) {
    outputArray.push(`${minBath} ba`);
  }
  if (minSf) {
    outputArray.push(`${minSf} sq ft`);
  }
  if (propType) {
    outputArray.push(`${formatPropTypes(propType)}`);
  }
  const output = outputArray.join(` | `);
  return output;
}

/**
 * Helper function to keep track of the number of actions an unregistered user performs
 * @param {string} localStorageKey - a key used to check/store in localStorage
 * @param {number} maxActions - max number of actions
 * @param {Function} successCallback - a function to be executed if success
 * @param {Function} failureCallback - a function to be executed if failure
 */
export function localStorageTracker(
  localStorageKey: string,
  maxActions: number,
  successCallback: Function,
  failureCallback: Function,
  forceReset: boolean = false,
) {
  // The number of times this action has already been performed, if any
  const noOfActions: string | null = localStore.getItem(localStorageKey);
  if (!noOfActions) {
    localStore.setItem(localStorageKey, '1');
    return successCallback();
  }

  let count = parseInt(noOfActions, 10);
  if (count < maxActions) {
    // The user hasn't hit the max allowable actions yet. Allow them to proceed
    count = count + 1;
    localStore.setItem(localStorageKey, count.toString());
    return successCallback();
  } else {
    // Reset when the failure callback is going to be triggered, so that it will
    // happen again. For example, we want the Random Home signup modal
    // to trigger every 5 views until you sign up
    if (forceReset) {
      localStore.removeItem(localStorageKey);
      return failureCallback();
    }
    // Make sure we only show the prompt once per day
    return promptHelper(
      storageKeys.homeVisitDate,
      failureCallback,
      successCallback,
    );
  }
}

/**
 * Helper function to check and trigger an action only once per day
 * For example, this is used to only show a signup prompt on the homepage once per day
 * @param {string} key - a key to check/store in localstorage
 * @param {Function} failureCallback - a function to be executed if failure
 */
export function promptHelper(
  key: string,
  failureCallback: Function,
  successCallback?: Function,
) {
  const currentDate = moment().format('L');
  const storedDate: string | null = localStore.getItem(key);
  if (!storedDate || (storedDate && storedDate !== currentDate)) {
    // First open, or the page was opened on a new day
    localStore.setItem(key, currentDate);
    failureCallback();
  } else {
    // Re-opened the page on the same day
    if (successCallback) {
      successCallback();
    }
    return;
  }
}

/**
 * Helper function to check if the browser allows storing cookies
 * @export
 * @returns
 */
export function checkCookies() {
  return typeof navigator !== 'undefined' && navigator.cookieEnabled;
}

/**
 * Helper function to limit the input from exceeding the max value
 * Helper function to limit an input from exceeding a max value
 * @param {string} value - max value
 * @param {string} suffix - suffix
 * @returns {number} - Correct value ex. if max is 10 and the inputValue is 99, it returns 10.
 */
export const maxValue = (max: number = 10, suffix?: string) => (
  value: string,
) => {
  const onlyNums = value && parseInt(value.replace(/[^\d]/g, ''), 10);
  if (!onlyNums) {
    return '';
  }
  if (onlyNums >= max) {
    return suffix ? `${max}${suffix}` : max;
  } else if (onlyNums < 0) {
    return 0;
  } else {
    return suffix
      ? `${parseInt(`${onlyNums}`, 10)}${suffix}`
      : parseInt(`${onlyNums}`, 10);
  }
};

/**
 * Helper function to return null if no character is present
 * @param {*} value - a value typed in the TextField
 * @returns {string | null} - return null if input is empty else returns same value
 */
export function normalizeText(value: any) {
  if (!value) {
    return null;
  }
  if (typeof value === 'string') {
    return value.trim();
  }
  return value;
}

/**
 * Helper function to group an array of objects based on a key with same value
 * @param {Array<Object>} objectArray - an array of objects
 * @param {string} property - a key based on which the array has to be grouped
 * @returns {Object} - Returns the groupedResults and sameGroup(grouping which has more than one)
 */
export function groupBy(
  objectArray: any[],
  property: string,
  property2?: string,
) {
  const groupedResults = objectArray.reduce((acc: any, obj: any) => {
    let key = `${obj[property]}`;
    if (property2) {
      key = `${obj[property]}${obj[property2]}`;
    }
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
  const sameGroup = [];
  for (const item in groupedResults) {
    if (groupedResults[item].length > 1) {
      sameGroup.push(groupedResults[item]);
    }
  }
  return { groupedResults, sameGroup };
}

/**
 * Helper function to use a photo for a listing
 * @param {listing} Listing - a Listing object
 * @returns {string} - The URL of a photo to use
 */
export function getListingPhotoList(
  listing: any,
  size: 'small' | 'medium' | 'large' | 'tall',
) {
  if (!listing || Object.keys(listing).length === 0) {
    return [];
  }

  const listSize = Math.max(
    (listing.photoTallList && listing.photoTallList.length) || 0,
    (listing.photoLargeList && listing.photoLargeList.length) || 0,
    (listing.photoMediumList && listing.photoMediumList.length) || 0,
    (listing.photoSmallList && listing.photoSmallList.length) || 0,
  );
  const photoList = new Array(listSize).fill(null).map((_: null, i: number) => {
    let photo;
    photo =
      listing.photoTallList &&
      listing.photoTallList[i] !== missingImage &&
      listing.photoTallList[i];
    if (size === 'tall') {
      return photo || missingImage;
    }

    photo =
      (listing.photoLargeList &&
        listing.photoLargeList[i] !== missingImage &&
        listing.photoLargeList[i]) ||
      photo;
    if (photo && size === 'large') {
      return photo;
    }

    photo =
      (listing.photoMediumList &&
        listing.photoMediumList[i] !== missingImage &&
        listing.photoMediumList[i]) ||
      photo;
    if (photo && (size === 'medium' || size === 'large')) {
      return photo;
    }

    photo =
      (listing.photoSmallList &&
        listing.photoSmallList[i] !== missingImage &&
        listing.photoSmallList[i]) ||
      photo;
    return photo || missingImage;
  });

  return photoList;
}

/**
 * Helper function to use a photo for a listing
 * @param {listing} Listing - a Listing object
 * @returns {string | null} - The URL of a photo to use
 */
export function getListingPhoto(
  listing: any,
  size: 'small' | 'medium' | 'large' | 'tall',
) {
  if (!listing || Object.keys(listing).length === 0) {
    return null;
  }

  let photo;
  photo =
    listing.photoTallList &&
    listing.photoTallList.find((p: any) => p && p !== missingImage);
  if (size === 'tall') {
    return photo || missingImage;
  }

  photo =
    (listing.photoLargeList &&
      listing.photoLargeList.find((p: any) => p && p !== missingImage)) ||
    photo;
  if (photo && size === 'large') {
    return photo;
  }

  photo =
    (listing.photoMedium !== missingImage && listing.photoMedium) ||
    (listing.photoMediumList &&
      listing.photoMediumList.find((p: any) => p && p !== missingImage)) ||
    photo;
  if (photo && (size === 'medium' || size === 'large')) {
    return photo;
  }

  photo =
    (listing.photoSmall !== missingImage && listing.photoSmall) ||
    (listing.photoSmallList &&
      listing.photoSmallList.find((p: any) => p && p !== missingImage)) ||
    photo;
  return photo || missingImage;
}

/**
 * Removes any "missing" photos from a list of photos.
 * If we would return no photos, it returns one "missing" image
 * @param {listing} Listing - a Listing object
 * @returns {string | null} - The URL of a photo to use
 */
export function removeMissingPhotos(photolist: string[]) {
  const removed = photolist.filter(a => a !== missingImage);
  if (removed.length === 0) {
    return [missingImage];
  }
  return removed;
}

export function formatOpenHouse(listing: any) {
  let openHouseOrUpdate = `UPDATED ${moment(listing.updateDate)
    .fromNow()
    .toLocaleUpperCase()}`;
  if (
    listing.openHouses &&
    listing.openHouses.length > 0 &&
    moment(listing.openHouses[0].date) > moment()
  ) {
    const { date, duration } = listing.openHouses[0];
    const time = moment.utc(date);
    openHouseOrUpdate = `OPEN ${moment(time)
      .format('dddd')
      .toLocaleUpperCase()}\n${time.format('h:mmA')} - ${time
      .add(duration, 'minutes')
      .format('h:mmA')}`;
  }
  return openHouseOrUpdate;
}

/**
 * Format a string of listing details
 * @export
 * @param {Object} listing - a Listing object
 * @param {boolean} [withId=false] - True if we want to include the listing's ID in the string
 * @returns {string} - Ex. '$949,000 | 7br 3ba | 2,620sf | #77111222
 */
export function formatListingDetails(
  listing: any,
  withPrice: boolean = false,
): string {
  const price = `$${normalizePrice(listing.listPrice)}`;
  const bath = `${formatBathrooms(listing)} ba`;
  const sf = `${
    listing.squareFeet ? normalizePrice(listing.squareFeet) : '--'
  } sqft`;
  if (withPrice) {
    return `${price} | ${listing.noBedrooms} br ${bath} | ${sf}`;
  }
  return `${listing.noBedrooms} br | ${bath} | ${sf}`;
}

/**
 * Find index of an element in an array based on key
 * @export
 * @param {any} listingToFind - Listing object
 * @param {string} key - Key value
 * @param {any[]} listings - Array of listings
 * @returns {number} - Index of the found element
 */
export function findListingIndex(
  listingToFind: any,
  key: string,
  listings: any[],
) {
  const index = listings
    .map((listing: any) => listing[key])
    .indexOf(listingToFind[key]);
  if (index < 0) {
    return 0;
  }
  return index;
}

/**
 * Remove blocked listings from map-feed, So blocked listings are not visible in mapView
 * @export
 * @param {any[]} feed - Map feed
 * @param {any} blockedListing - Blocked listing
 * @returns {any[]} - Removed Blocked listing from feed
 */
export function removeBlockedListings(feed: any[], blockedListing: any) {
  return feed.filter((listing: any) => {
    return listing.listingMlsId !== blockedListing.id;
  });
}

/**
 * Get the key property by its value
 * @param object
 * @param value
 */
export function getKeyByValue(object: any, value: string) {
  return Object.keys(object).find(key => object[key] === value);
}

/**
 * Extract correct URL from virtualTourUrl field.
 * @param {string} url - virtualTourUrl (eg., Matterport tour - 10 Esplanade https://my.matterport.com/show/?m=TpTyk6K6Gkn)
 * @returns {string} - returns only the URL https://my.matterport.com/show/?m=TpTyk6K6Gkn
 */
export function extractValidUrl(url: string) {
  var matches = url.match(/\bhttps?:\/\/\S+/gi);
  if (matches && matches.length > 0) {
    return matches[0];
  } else {
    return undefined;
  }
}

/**
 * Format the school levels to a range format
 * @param {string} levels - School levels ex. 1,2,3,4,5,6,7,8,9,10,12
 * @returns {string} - Return level range ex 1 - 12
 */
export function formatSchoolLevel(levels: string) {
  const levelsSplitUp = levels.split(',');
  if (levelsSplitUp.length > 2) {
    // Replace 'KG' with 'K' for kindergarten
    for (let i in levelsSplitUp) {
      if (levelsSplitUp[i] === 'KG') {
        levelsSplitUp[i] = 'K';
      }
    }
    return `${levelsSplitUp[0]} - ${levelsSplitUp[levelsSplitUp.length - 1]}`;
  } else {
    return levels;
  }
}

/**
 * Format listing agent details (i.e, Mike Sokolowski #123456, Torii, Inc. #01973246))
 * @param {Object} listingObject - Listing object with agents details
 * @returns {String | undefined} - return formatted agent details
 */
export const formatAgentDetails = ({
  agentName,
  agentMlsId,
  brokerName,
  brokerMlsNumber,
}: any) => {
  if (!agentName && !agentMlsId && !brokerName && !brokerMlsNumber) {
    return null;
  }
  const formattedAgentName = agentName ? agentName : '';
  const formattedAgentId = agentMlsId ? ` #${agentMlsId}` : '';
  const formattedBrokerName = brokerName ? `${brokerName}` : '';
  const formattedBrokerMlsNumber = brokerMlsNumber
    ? ` #${brokerMlsNumber}`
    : '';
  const comma =
    (formattedAgentName || formattedAgentId) &&
    (formattedBrokerName || formattedBrokerMlsNumber)
      ? ', '
      : '';
  return `${formattedAgentName}${formattedAgentId}${comma}${formattedBrokerName}${formattedBrokerMlsNumber}`;
};

/**
 * Format Listing address for our Homebuzz tool
 * @param {Object} listing - Listing details
 * @param {boolean} short
 * @returns - Listing address
 */
export const socialListingAddress = (listing: any, short: boolean = false) => {
  if (!listing) {
    return '';
  }
  let unitNo = '';
  let streetNumToUse = '';
  if (listing.unitNo) {
    unitNo = ` ${listing.unitNo}`;
  }
  if (listing.streetNo) {
    streetNumToUse = listing.streetNo;
  }
  const streetAddress = `${streetNumToUse} ${listing.streetName}${unitNo}`;
  if (short) {
    return streetAddress;
  }
  return `${streetAddress}, ${listing.city}, ${abbreviateState(
    listing.state,
  )}, ${listing.zipcode && listing.zipcode.substring(0, 5)}`;
};

export function generateRandomUsernames() {
  const uniqueName: string = uniqueNamesGenerator({
    dictionaries: [adjectives, colors, animals],
  });
  return uniqueName;
}

/**
 * Frame title matching the status of a listing
 * @param {string} status - Status of a listing
 * @returns - Matching title string
 */
export const formatPriceHistoryTitle = (status: string) => {
  let textToReturn = status;
  if (status === 'New') {
    textToReturn = 'Listed For Sale';
  } else if (status === 'Expired' || status === 'Canceled') {
    textToReturn = 'Listing Removed';
  }
  return textToReturn;
};

/**
 * Frame query param to get listing photos as per
 * the personalized filters.
 */
export const getPhotosByRoom = (rooms: string | undefined) => {
  let queryToReturn = '';
  if (rooms) {
    queryToReturn = `?photosByRoom=${rooms}`;
  }
  return queryToReturn;
};

/**
 * Helper function to check if listing price is greater than minimum supported price
 * Skips the check if overrideLimit property is true
 * @param {Object} listing - Listing object
 * @returns {boolean}
 */
export function allowListingActions(listing: any) {
  if (listing.overrideLimit || listing.listPrice >= MINIMUM_SUPPORTED_PRICE) {
    return true;
  } else {
    return false;
  }
}

/**
 * Helper function to extract distinct mls sources from listings array
 * @param listings - Array of listings
 * @returns - Array of disclaimer properties
 */
export function extractDisclaimerProperties(listings: any[]) {
  if (listings.length === 0) {
    return [];
  }
  const logoFileNames: any = [];
  const distinctMls: any = Array.from(
    new Set(listings.map((listing: any) => listing.mlsSourceShortName)),
  );
  distinctMls.map((mls: string) => {
    switch (mls) {
      case 'mls_pin':
        logoFileNames.push({
          slug: 'mlspin.png',
          disclaimerText: COPYRIGHT_TEXT[mls],
        });
        break;
      case 'sfar':
        logoFileNames.push({
          slug: 'sfar.png',
          disclaimerText: COPYRIGHT_TEXT[mls],
        });
        break;
      case 'calrets':
        logoFileNames.push({
          slug: 'mlslistings.png',
          disclaimerText: COPYRIGHT_TEXT[mls],
        });
        break;
      case 'crmls':
        logoFileNames.push({
          slug: 'crmls.png',
          disclaimerText: COPYRIGHT_TEXT[mls],
        });
        break;
      case 'neren':
        logoFileNames.push({
          slug: 'neren.png',
          disclaimerText: COPYRIGHT_TEXT[mls],
        });
        break;
      case 'bareis':
        logoFileNames.push({
          slug: 'bareis.png',
          disclaimerText: COPYRIGHT_TEXT[mls],
        });
        break;
      case 'bright_mls':
        logoFileNames.push({
          slug: 'brightMls.jpeg',
          disclaimerText: COPYRIGHT_TEXT[mls],
        });
        break;
      default:
        null;
    }
  });
  return uniq(logoFileNames);
}

export const getQueryParams = () => {
  const parsed = queryString.parse(location.search);

  return parsed;
};

export interface Progress {
  basicsCompleted: boolean;
  neighborhoodCompleted: boolean;
  lifeStyleCompleted: boolean;
}

export function checkIfSurveyCompleted(values: any, sendProgress?: boolean) {
  const progress: Progress = {
    basicsCompleted: false,
    lifeStyleCompleted: false,
    neighborhoodCompleted: false,
  };

  if (isEmpty(values)) {
    if (sendProgress) {
      return progress;
    }
    return false;
  }

  progress.basicsCompleted =
    !!values.state &&
    !!values.city &&
    !!values.parking &&
    !isEmpty(values.homeType);

  progress.neighborhoodCompleted = !!values.soundPreferences;

  progress.lifeStyleCompleted = !!values.importantRoom;
  if (sendProgress) {
    return progress;
  }

  return (
    progress.basicsCompleted &&
    progress.neighborhoodCompleted &&
    progress.lifeStyleCompleted
  );
}

/**
 * Return formatted utm params
 * @param {object} utmParams - all the utm parameters
 * @returns {string} - formatted utm params
 */
export function formatUtmParams({
  utmCampaign,
  utmMedium,
  utmSource,
  utmTerm,
}: any) {
  let params = '';
  if (!utmCampaign && !utmMedium && !utmSource && !utmTerm) {
    return params;
  }
  if (utmSource) {
    params += `utm_source=${utmSource}`;
  }
  if (utmMedium) {
    params += `&utm_medium=${utmMedium}`;
  }
  if (utmCampaign) {
    params += `&utm_campaign=${utmCampaign}`;
  }
  if (utmTerm) {
    params += `&utm_term=${utmTerm}`;
  }
  return `?${params}`;
}

/**
 * Format time to display in showings calendar
 * @param value - Moment object
 * @param addSuffix - true if am/pm needs to be added at the end
 * @returns - Formatted time ex: 4 | 4:15 | 3 pm | 3:15 pm
 */
/* istanbul ignore next */
export function formatTime(value: any, addSuffix?: boolean) {
  if (value.minute()) {
    return value.format(addSuffix ? 'h:mm A' : 'h:mm');
  } else {
    return value.format(addSuffix ? 'h A' : 'h');
  }
}

/**
 * Calculate date and display the Showing time
 * @param date - showing date
 * @returns - formatted date -
 */
/* istanbul ignore next */
export function showingTime(date: any) {
  const eventDate = moment(date);
  const timeSpanValue = `${formatTime(eventDate)} - ${formatTime(
    eventDate.add(60, 'minutes'),
    true,
  )}`;
  return timeSpanValue;
}

export const PendingColor = '#FFD750'; // Yellow
export const ConfirmedColor = '#195A50'; // Green
export const OpenHouseColor = '#463264'; // Torii Plum
export const DisabledColor = '#E2E2E2'; // grey
// Calendar Min Date
export const StartDate = moment()
  .subtract(1, 'month')
  .startOf('month')
  .toDate();
// Calendar Max Date
export const MaxDate = moment()
  .add(1, 'months')
  .endOf('month')
  .toDate();

/**
 * Helper to frame title for the calendar event
 * @param showing - Showing details
 * @returns - returns -> Tour @ 6 - 7 PM
 */
/* istanbul ignore next */
export const frameTitle = (showing: any) => {
  const timeSpanValue = showingTime(showing.date);
  if (showing.isOpenHouse) {
    return `Open House @\n${timeSpanValue}\n(ATTENDING)`;
  }
  return `Tour @\n${timeSpanValue}\n(${showing.status.toUpperCase()})`;
};

/**
 * Generate Calendar events for showings
 * @param classes - Styles classes
 * @param showingsData - Collection of showings
 * @returns - Calendar Events
 */
/* istanbul ignore next */
export const frameCalendarEvents = (classes: any, showingsData: any) => {
  return showingsData.map((showing: any) => {
    const isOlderEvent = moment(showing.date).diff(moment(), 'days') < 0;
    return {
      id: showing.id,
      title: frameTitle(showing),
      start: showing.date,
      overlap: true,
      interactive: false,
      editable: false,
      selectable: false,
      isOlderEvent,
      color: isOlderEvent
        ? DisabledColor
        : showing.isOpenHouse
        ? OpenHouseColor
        : showing.status === 'confirmed'
        ? ConfirmedColor
        : PendingColor,
      backgroundColor: isOlderEvent
        ? DisabledColor
        : showing.isOpenHouse
        ? OpenHouseColor
        : showing.status === 'confirmed'
        ? ConfirmedColor
        : PendingColor,
      textColor: isOlderEvent
        ? '#636363'
        : showing.isOpenHouse
        ? 'white'
        : showing.status === 'confirmed'
        ? 'white'
        : 'black',
      className: classes.calendarEventBox,
      classNames: [
        isOlderEvent
          ? classes.calendarEventDisabled
          : showing.isOpenHouse
          ? classes.calendarEventOpenhouseConfirmed
          : showing.status === 'confirmed'
          ? classes.calendarEventConfirmedBox
          : classes.calendarEventPendingBox,
      ],
      timeZone: showing.timezone,
      showingDetails: showing,
    };
  });
};

/**
 * Generate Calendar events for showings with openHouse as true
 * @param classes - Styles classes
 * @param showingsData - Collection of showings
 * @returns - Calendar Events
 */
/* istanbul ignore next */
export function frameOpenHouseEvents(classes: any, openHouses: any) {
  return openHouses.map((openHouse: any, index: number) => {
    const { startDateTime, endDateTime, duration } = openHouse;
    const time = moment.utc(startDateTime);
    const start = time.format('YYYY-MM-DD[T]HH:mm:ss');
    const end = moment.utc(endDateTime).format('YYYY-MM-DD[T]HH:mm:ss');
    const title = `Open House @\n${formatTime(time)} - ${formatTime(
      time.add(duration, 'minutes'),
      true,
    )}\n(Click to attend)`;

    return {
      id: index,
      title,
      start,
      end,
      overlap: true,
      interactive: false,
      editable: false,
      selectable: false,
      color: '#463264',
      backgroundColor: 'white',
      textColor: '#463264',
      className: classes.calendarEventBox,
      classNames: [classes.calendarEventOpenHouse],
      openHouseDetails: { ...openHouse, start, end },
    };
  });
}

/**
 * Helper function to get up-coming showings and splitup showings and open house visits
 * @param allShowings - All showings for a user
 * @returns - Returns {upComingShowings, upComingOpenHouses}
 */
/* istanbul ignore next */
export function splitShowings(allShowings: any[]) {
  const upComingShowings: any = [];
  const upComingOpenHouses: any = [];

  const upComings = sortArrayOfObjects(
    allShowings.filter((showing: any) =>
      moment(showing.date).isSameOrAfter(moment(), 'day'),
    ),
    'date',
  );
  upComings.map(showing => {
    if (showing.isOpenHouse) {
      upComingOpenHouses.push(showing);
    } else {
      upComingShowings.push(showing);
    }
  });

  return { upComingShowings, upComingOpenHouses };
}

const StartTime = '04:59:00 AM';
const EndTime = '22:00:00 PM';
const DateFormat = 'hh:mm:ss A';

/**
 * Helper to check if the time/date selected by user is valid
 * @param selectedValue - date
 * @returns - true/false based on selected time
 */
/* istanbul ignore next */
export function isValidTime(selectedValue: any) {
  const selectedTime = moment(
    moment(selectedValue).format(DateFormat),
    DateFormat,
  );
  const beforeTime = moment(StartTime, DateFormat);
  const afterTime = moment(EndTime, DateFormat);
  const isValid = selectedTime.isBetween(beforeTime, afterTime);
  return isValid;
}

/**
 * Helper function to remove openHouses for which the user has opted to attend
 * @param showings - Showings data
 * @param openHouses - Open house data
 * @param listingId - listingMlsId
 * @returns
 */
/* istanbul ignore next */
export function extractAvailableOpenHouses(
  showings: any[],
  openHouses: any[],
  listingId: number,
) {
  if (isEmpty(openHouses)) {
    return [];
  }
  if (isEmpty(showings)) {
    return openHouses;
  }
  const openHouseVisits = showings.filter(
    showing => showing.listingId == listingId && showing.isOpenHouse,
  );
  const availableOpenHouses: any[] = [];
  const ohVisitsTimes = openHouseVisits.map(ohVisit => ohVisit.date);
  openHouses.map(openHouse => {
    const time = moment.utc(openHouse.startDateTime);
    const start = time.format('YYYY-MM-DD[T]HH:mm:ss');
    if (!ohVisitsTimes.includes(start)) {
      availableOpenHouses.push(openHouse);
    }
  });
  return availableOpenHouses;
}

/**
 *
 * @param showing - showing data
 * @returns color of the event
 */
export function dotColor(showing: any): string {
  return showing.isOpenHouse
    ? OpenHouseColor
    : showing.status === 'confirmed'
    ? ConfirmedColor
    : PendingColor;
}

/**
 * Helper function to get location from the URL (For example, /somerville-ma-usa to Somerville, MA, USA)
 * @param router - the router object
 * @returns - the converted string
 */
export function getURLLocation(router: any): string | null {
  const filters = JSON.parse(get(router, 'query.filters', '{}'));
  // This is for handling the clear button in the map
  const useCurrentLocation =
    router.asPath && router.asPath.split('/').length === 2 ? false : true;

  const locationForDescription = filters.area
    ? filters.area
    : filters.locationName
    ? filters.locationName
    : '';
  if (!locationForDescription || !useCurrentLocation) return null;
  let temp = locationForDescription.split('-');
  temp = temp.map((location: any, index: number) => {
    location = location.replace('/', '');
    if (index === 0)
      location = location.charAt(0).toUpperCase() + location.slice(1);
    else location = location.toUpperCase();
    if (index !== temp.length - 1) {
      location += ',';
    }
    return location;
  });
  return temp.join(' ');
}

/**
 * Helper function to replace special apostrophe with single quote
 * @param inputText - Text that has special characters for apostrophe ex: winterâs club
 * @returns returns readable text winter's club
 */
export function replaceSplApostrophe(inputText: string | null): string {
  if (!inputText) {
    return '';
  }
  // Replacing all instances in the string
  return inputText.replace(new RegExp('â', 'g'), "'");
}

/**
 * Helper function to format Avo event values
 * @param user
 * @returns
 */
export function formatAvoEventValues(user: any) {
  const extraEventVals: any = {};
  if (!user || isEmpty(user)) {
    return extraEventVals;
  }
  const {
    email,
    firstName,
    lastName,
    phoneNumber,
    signupPath,
    utmCampaign,
    utmMedium,
    utmSource,
  } = user;
  extraEventVals.Email = email;
  extraEventVals.Phone = phoneNumber;
  extraEventVals['First Name'] = firstName;
  extraEventVals['Last Name'] = lastName;
  extraEventVals['Signup Path'] = signupPath;
  if (utmCampaign) {
    extraEventVals['UTM Campaign'] = utmCampaign;
  }
  if (utmMedium) {
    extraEventVals['UTM Medium'] = utmMedium;
  }
  if (utmSource) {
    extraEventVals['UTM Source'] = utmSource;
  }
  return extraEventVals;
}
